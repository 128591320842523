import { FC } from 'react';
import Tooltip from '../atoms/Tooltip';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';

type AdditionalContextProps = {
    creditLimitFormated: string;
    limitCreditDivideCreditRuralInPercentual: string | number; // Aceita string ou number diretamente.
    recommendation: string;
    creditRuralFormatedValue: string;
    creditRuralStatus: string;
};

const ReferralCreditLimitDivideCreditLimitRural: FC<AdditionalContextProps> = ({ creditLimitFormated = '0',
                                                                                   limitCreditDivideCreditRuralInPercentual = '0',
                                                                                   creditRuralFormatedValue='0',
                                                                                   recommendation,
                                                                                   creditRuralStatus='ok'
                                                                               }) => {
    // Função para adicionar porcentagem ao valor numérico.
    const formatPercent = (value: string | number) => {
        const numericValue = typeof value === 'string' ? Number(value) : value;
        return isNaN(numericValue) ? value : `${numericValue}%`;
    };

    // Função para definir a classe de cor baseada no valor numérico.
    const getColorClass = (value: string | number) => {
        const numericValue = typeof value === 'string' ? Number(value) : value;

        if (isNaN(numericValue)) {
            return 'text-red-600';
        } else if (numericValue <= 20) {
            return 'text-green-600';
        } else if (numericValue > 20 && numericValue <= 30) {
            return 'text-yellow-600';
        } else {
            return 'text-red-600';
        }
    };



    if (recommendation !== 'processing') {
        switch (creditRuralStatus) {
            case 'error':
                return (
                    <Tooltip content="Erro ao carregar credito rural M0 do farmer analysis">
                        <FaTimes style={{ color: 'rgb(242, 5, 37)' }} />
                    </Tooltip>
                )
            case 'notfound':
                return (
                    <Tooltip content="Credito rural M0 não encontrado">
                        <FaExclamationCircle style={{ color: 'rgb(255, 255, 0)' }} />
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip
                        content={`${creditLimitFormated}  /  ${creditRuralFormatedValue}`}>
                    <span
                        className={`mr-2 ${getColorClass(limitCreditDivideCreditRuralInPercentual)}`}>{formatPercent(limitCreditDivideCreditRuralInPercentual)}</span>
                    </Tooltip>
                )
        }
    } else {
           return <span className={`mr-2`}>-</span>
    }

};

export default ReferralCreditLimitDivideCreditLimitRural;
